<template>
<div style="padding-bottom: 30px; ">
    <!-- <v-row style="margin-top: 0px" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-dialog persistent v-model="forgetPasswordDialog" width="500">
        <v-card>
            <!-- <v-card-title>
                <span style="color:#a84192; font-size:18px;">เพิ่มคลินิก/สถานพยาบาล</span>
            </v-card-title> -->

            <v-card-text>
                <v-container>
                    <v-card class="" style="">
                        <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                            <span> {{form_title}}
                            </span>
                        </v-card-title>

                        <!-- <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color:#ac55991f!important;
              color: #a84192;
            ">
                            <span>
                                <li>{{form_sub_title}}</li>
                            </span>
                        </v-card-subtitle> -->

                        <v-card-text style="border-radius: 7px; padding-top: 25px">

                            <v-row v-if="user_type == 'user_provider'" dense align="center">
                                <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                                    <v-text-field v-model="mdnumber" :rules="nameRules" label="เลขที่ใบประกอบวิชาชีพเวชกรรม" dense outlined required></v-text-field>
                                    <v-text-field  :type="tel" counter="6" v-model="passcode" :rules="passcode_rule" label="กำหนด Passcode ใหม่ 6 หลัก" dense outlined required></v-text-field>
                                    <v-text-field  :type="tel" counter="6" v-model="passcode_confirm" :rules="passcode_confirm_rule" label="ยืนยัน Passcode ใหม่" dense outlined required></v-text-field>
                                 
                                </v-form>
                            </v-row>

                            <v-row v-if="user_type == 'user_osm'" dense align="center">
                                <v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
                                    <v-text-field v-model="idcard" :rules="nameRules" counter="13" type="number" label="เลขบัตรประชาชน" dense outlined required></v-text-field>
                                    <v-text-field  :type="tel" counter="6" v-model="passcode" :rules="passcode_rule" label="กำหนด Passcode ใหม่ 6 หลัก" dense outlined required ></v-text-field>
                                    <v-text-field  :type="tel" counter="6" v-model="passcode_confirm" :rules="passcode_confirm_rule" label="ยืนยัน Passcode ใหม่" dense outlined required></v-text-field>
                                </v-form>
                            </v-row>

                        </v-card-text>
                    </v-card>
                    <br>
                    <v-row style=" justify-content:space-evenly ;">
                        <v-btn color="primary" outlined style="color:#a84192" @click="forgetPasswordDialog = false">
                            ยกเลิก
                        </v-btn>
                        <v-btn color="#a84192" width="40%" class="text-white" @click="resetPassword">
                            ตกลง
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-row class="align-center d-flex h-0">
        <v-col lg="4" sm="6" class="mx-auto" style="padding: 0px 0px 0px 0px">
            <v-card class="" style="">
                <v-card-title class="text-primary" style="
              justify-content: center;
              font-weight: 600 !important;
              padding-bottom: 20px;
              font-size: 24px;
            ">
                    <span>{{form_title}}</span>
                </v-card-title>

                <v-card-subtitle style="
              height: 23px;
              text-align: center !important;
              background-color: #ac55991f !important;
              color: #a84192;
            ">
                    <span>
                        <li>{{form_sub_title}}</li>
                    </span>
                </v-card-subtitle>
                <br>
                <br>

                <v-row>
                    <v-col cols="12" style="text-align:center; color:#a84192">
                        <span>PASSCODE</span>
                    </v-col>
                </v-row>
                <br>
                <!-- user_provider -->
                <!-- <div v-if="user_type == 'user_provider'" dense align="center"> -->
                <v-row class="d-flex" v-if="user_type == 'user_provider'" style="justify-content: space-evenly;">
                    <vue-otp-2 length="6" join-character="-" inputmode="numeric" pattern="[0-9]*" @onComplete="checkPasscode" />
                </v-row>
                <!-- user_osm -->
                <v-row class="d-flex" v-if="user_type == 'user_osm'" style="justify-content: space-evenly;">
                    <vue-otp-2 length="6" join-character="-" inputmode="numeric" pattern="[0-9]*" @onComplete="checkPasscode" />
                </v-row>
                <br>
                <br>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#a84192" width="40%" text @click="forgetPasswordDialog=true">
                        ลืมรหัสผ่าน
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-col>
    </v-row>
    <br>
    <br>
    <br>
    <br>

    <!-- {{selected_provider}} -->
    <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
        <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
            <v-icon small>west</v-icon>
        </v-btn>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <v-btn @click="goCheckPasscode" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
            ยืนยันข้อมูล
        </v-btn>
    </v-row>
    <!-- {{selected_provider_list}} -->

    <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
        <v-row class="align-center" justify="center">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="goCheckPasscode" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>
    </v-footer>
</div>
</template>

<script>
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "register-select-type",
    components: {
        WaitingModal,
    },
    data() {
        return {
            query: '',
            idcard: '',
            mdnumber: '',
            show1: false,
            forgetPasswordDialog: false,
            passcode: '',
            passcode_confirm: '',
            dialog: false,
            active_waiting: false,
            title: '',
            status: '',
            form_title: '',
            form_sub_title: '',
            menu: false,
            user_type: "",
            valid: true,
            new_passcode: '',
            md_id_number: '',
            passcode_rule: [
                (v) => !!v || "โปรดกรอกข้อมูล",
                v => (v && v.length == 6) || "pass code 6 หลัก"
            ],
            passcode_confirm_rule: [
                (v) => !!v || "โปรดกรอกข้อมูล",
                v => (v == this.passcode) || "pass code ไม่ตรงกัน"
            ],
            nameRules: [
                v => !!v || "*",

            ],

        };
    },

    mounted() {
        this.user_type = this.$store.state.login_detail.user_type
        this.getFromTitle()

    },

    methods: {
        getValueFrom() {

            // this.query = this.$route.query
            //console.log('this.query', this.query)
            if (this.$route.query.goto == 'dashboard_summary') {
                this.$router.push("/dashboard_summary");
            }
            if (this.$route.query.goto == 'login') {
                this.$router.push("/login");
            }
            if (this.$route.query.goto == 'scan_accessdata') {
                this.$router.push("/scan_accessdata");
            }
            if (this.$route.query.goto == 'scan_azq') {
                this.$router.push("/scan_azq");
            }


            
        },
        checkPasscode(val) {
            this.passcode = val.join('')
            //console.log('passcode: ', this.passcode);
            this.goCheckPasscode()
        },

        async goCheckPasscode() {
            //console.log('passcode: ', this.passcode);
            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังตรวจสอบ',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/passcode_check', {
                "user_id": this.$store.state.login_detail.user_id,
                "passcode": this.passcode,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail == 'valid_passcode') {
                    this.title = 'PASSCODE ถูกต้อง';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.getValueFrom()
                    }, 2000)

                } else if (response.data.detail == 'not_valid_passcode') {
                    // this.active_waiting = false
                    this.title = 'PASSCODE ไม่ถูกต้อง โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                } else {
                    // this.active_waiting = false
                    this.title = 'โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                }

            })

        },

        async resetPassword() {
            //console.log('passcode: ', this.passcode);
            this.dialog_new = false
            this.active_waiting = true
            this.title = 'กำลังรีเซ็ต PASSCODE',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/reset_passcode', {
                "user_id": this.$store.state.login_detail.user_id,
                "md_id_number": this.mdnumber ? this.mdnumber : this.idcard,
                "passcode": this.passcode,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data.detail == 'reset_success') {
                    this.title = 'รีเซ็ต PASSCODE สำเร็จ';
                    this.status = 'success'
                    this.forgetPasswordDialog = false
                    // setTimeout(() => {
                    //     this.active_waiting = false
                    // }, 2000)

                } else if (response.data.detail == 'reset_not_success') {
                    // this.active_waiting = false
                    this.title = 'ID ไม่ถูกต้อง โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                } else {
                    // this.active_waiting = false
                    this.title = 'โปรดลองใหม่อีกครั้ง',
                        this.status = 'fail'
                }

            })

        },

        // resetPassword(){
        //     //console.log('resetPassword: ', this.new_passcode);
        //     //console.log('resetPassword: ', this.md_id_number);
        // }

        handleOnComplete(value) {
            //console.log('OTP completed: ', value);
        },
        handleOnChange(value) {
            //console.log('OTP changed: ', value);
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
    

        update_status_wait(val) {
            this.active_waiting = val
        },

        getFromTitle() {
            if (this.user_type === 'user_provider') {
                this.form_title = 'โรงพยาบาล/คลินิก'
                this.form_sub_title = 'Healthcare provider'
            }
            if (this.user_type === 'user_osm') {
                this.form_title = 'อสม.'
                this.form_sub_title = 'Healthcare provider'
            }
        },

        validate() {
            const res = this.$refs.form_2.validate();
            //console.log(res)
            if (res) {
                this.submitRegister()
            }
            // 
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #FFFFFF;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #A84192;
}

.v-main__wrap {

    padding-bottom: 90px;
}

.v-select .vs__selected-options {
    flex-wrap: nowrap;
}

.v-btn__content {
    /*
    display:flex;
    flex-direction:column;*/
    font-size: 14px;
}

.vue-otp-2 {
    display: flex;
    justify-content: space-between;
}
</style>
